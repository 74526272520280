import {
  groupByOptions,
  GroupByValue,
  intervalOptions,
} from '@features/reporting/types/groupByTypes'
import {
  faChevronDown,
  faChevronUp,
  faListTree,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { Button } from 'components/buttons'
import { Radio } from 'components/form/radio/Radio'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twJoin } from 'tailwind-merge'
import { Section } from './Section'

interface Props {
  onChange: (value: GroupByValue) => void
  value: GroupByValue
}

export const GroupBy: FC<Props> = ({ onChange, value }) => {
  const [menuHidden, setMenuHidden] = useState(true)
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  useOnClickOutside(ref, () => setMenuHidden(true))

  return (
    <div className="relative" ref={ref}>
      <Button
        icon={menuHidden ? faChevronDown : faChevronUp}
        onClick={() => setMenuHidden((hidden) => !hidden)}
        variant={value.group === 'none' ? 'outlined' : 'outlined-active'}
      >
        <FontAwesomeIcon icon={faListTree} />
      </Button>
      {!menuHidden && (
        <div
          className={twJoin(
            'absolute right-0 top-[130%] z-30 w-[220px]',
            'bg-white rounded py-1 drop-shadow border-neutral-300',
          )}
        >
          <Section title={t('features.reporting.timeLog.groupBy')}>
            {groupByOptions.map((option) => (
              <label
                className="flex flex-row items-center cursor-pointer py-1"
                key={option}
              >
                <Radio
                  checked={value.group === option}
                  className="mr-2"
                  onChange={() =>
                    onChange({
                      group: option,
                      interval: option === 'none' ? 'none' : value.interval,
                    })
                  }
                />
                {t(`features.reporting.timeLog.${option}`)}
              </label>
            ))}
          </Section>
          <Section title="Group by interval">
            {value.group === 'none' && (
              <span className="text-neutral-600 text-xs my-2">
                {t(
                  'features.reporting.timeLog.selectGroupByAboveToEnableGroupByInterval',
                )}
              </span>
            )}
            {intervalOptions.map((option) => (
              <label
                className={twJoin(
                  'flex flex-row items-center py-1',
                  value.group === 'none'
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer',
                )}
                key={option}
              >
                <Radio
                  checked={value.interval === option}
                  onChange={() => onChange({ ...value, interval: option })}
                  className="mr-2"
                  disabled={value.group === 'none'}
                />
                {t(`features.reporting.timeLog.${option}`)}
              </label>
            ))}
          </Section>
        </div>
      )}
    </div>
  )
}
