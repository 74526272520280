import {
  faChevronDown,
  faChevronUp,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { twMerge } from '@lib/tailwind-merge'

interface Props {
  className?: string
  expanded: boolean
  icon?: IconDefinition
  label?: string
  onClick: () => void
  readonly: boolean
}

export const DropdownButton: FC<Props> = ({
  className,
  expanded,
  icon,
  label,
  onClick,
  readonly,
}) => {
  return (
    <button
      className={twMerge(
        'text-sm font-medium text-neutral-100 py-1 px-2.5',
        expanded && 'bg-neutral-700 rounded',
        className,
      )}
      disabled={readonly}
      onClick={onClick}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      {label}
      {!readonly && (
        <FontAwesomeIcon
          className="ml-1"
          icon={expanded ? faChevronUp : faChevronDown}
        />
      )}
    </button>
  )
}
