import { GroupByValue } from '../types/groupByTypes'

const groupParams = {
  none: null,
  user: 'user.id',
  facility: 'facility.id',
} as const

const intervalParams = {
  none: null,
  week: 'week',
  month: 'month',
  year: 'year',
} as const

export const toGroupByParams = (groupBy?: GroupByValue) => {
  if (!groupBy)
    return {
      groupColumn: null,
      groupDateInterval: null,
    }

  return {
    groupColumn: groupParams[groupBy.group],
    groupDateInterval: intervalParams[groupBy.interval],
  }
}
