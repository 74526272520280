import { FC, InputHTMLAttributes } from 'react'
import { twMerge } from '@lib/tailwind-merge'
import { twJoin } from 'tailwind-merge'

export const Radio: FC<InputHTMLAttributes<HTMLInputElement>> = ({
  className: overrideClassNames,
  ...props
}) => {
  const className = twMerge([
    'inline-block flex items-center border p-[1px] rounded-full border-neutral-400 max-w-min',
    overrideClassNames,
  ])

  return (
    <div className={className}>
      <input
        {...props}
        type="radio"
        className={twJoin(
          'border-none rounded-full cursor-pointer form-checkbox',
          'checked:bg-primary-600 checked:bg-none checked:disabled:bg-primary-400',
          'disabled:cursor-not-allowed disabled:bg-neutral-200 ',
          'indeterminate:bg-none indeterminate:bg-transparent',
          !props.checked && 'disabled:hover:bg-neutral-200',
        )}
        checked={props.checked ?? false}
      />
    </div>
  )
}
