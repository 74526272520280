import { Header } from '../../components/report-content/Header'
import { Table } from '../../components/report-content/Table'
import { useReportPage } from '@hooks/useReportData'
import { memo, useState, useEffect, useMemo } from 'react'
import { useFilter } from '../../hooks/useFilter'
import { Footer } from '../../components/report-content/Footer'
import { SubHeader } from '../../components/report-content/SubHeader'
import { useSort } from '../../hooks/useSort'
import { ReportTypeMap, SavedReport } from '../../types'
import { useUpdateReport } from '@features/reporting/hooks/useSavedReports'
import { useTranslation, TFunction } from 'react-i18next'
import { useAuth } from '@hooks/useAuth'
import { useSaveNewReport } from '../../hooks/useSaveNewReport'
import { useEditReport } from '@features/reporting/hooks/useEditReport'
import { toast } from '@lib/toasts'
import { convertToSortApiColumn } from '../../utils/convertToApiSortColumn'
import { useCsvExport } from '../../hooks/useCsvExport'
import { ColumnsSidebar } from './columns-sidebar/ColumnsSidebar'
import { defaultColumnIds } from '../../lib/defaultColumnIds'
import { infoByColumnId } from '@features/reporting/lib/infoByColumnId'
import { useDeleteReport } from '../../hooks/useDeleteReport'
import { GroupByValue } from '../../types/groupByTypes'
import { toGroupByParams } from '../../utils/toGroupByParams'
import { fromGroupByParams } from '../../utils/fromGroupByParams'

interface Props {
  savedReport?: SavedReport
  type: keyof ReportTypeMap
}

const dateFilterForReportType = <RT extends keyof ReportTypeMap>(
  type: RT,
  filter: ReportTypeMap[RT]['filters'],
) => {
  switch (type) {
    case 'time_log':
      return (filter as ReportTypeMap['time_log']['filters']).date
    case 'audit_log':
      return (filter as ReportTypeMap['audit_log']['filters']).datetime
  }
}

const subtitleForReportType = <RT extends keyof ReportTypeMap>({
  type,
  meta,
  t,
}: {
  type: RT
  meta: ReportTypeMap[RT]['page']['meta']
  t: TFunction<'translation', undefined>
}) => {
  switch (type) {
    case 'time_log':
      return t('common.totalHours', {
        count:
          ((meta as ReportTypeMap['time_log']['page']['meta']).columnTotals
            .durationInSeconds ?? 0) /
          60 ** 2,
      })
    case 'audit_log':
      return t('features.reporting.auditLog.totalEventCount', {
        count: (meta as ReportTypeMap['audit_log']['page']['meta']).total,
      })
  }

  throw new Error('Invalid report type')
}

export const Report = memo(function Report<RT extends keyof ReportTypeMap>({
  savedReport,
  type,
}: Props) {
  const { t } = useTranslation()
  const { user: signedInUser } = useAuth()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [columnIds, setColumnIds] = useState<ReportTypeMap[RT]['columnId'][]>(
    savedReport?.columns ?? defaultColumnIds(type),
  )
  const [filtersShown, setFiltersShown] = useState(savedReport === undefined) // Default filters to visible for new reports

  const [groupBy, setGroupBy] = useState<GroupByValue | undefined>(
    type === 'time_log' ? fromGroupByParams(savedReport) : undefined,
  )
  const { filter, filterParams, setFilter, clearFilter } = useFilter<RT>(
    savedReport ? savedReport.filters : {},
  )
  const [totalRowShown, setTotalRowShown] = useState(false)
  const { sorted, setSorted, toggleColumnSort } = useSort({
    column: savedReport ? savedReport.sortColumn : defaultColumnIds(type)[0],
    direction: savedReport ? savedReport.sortDirection : 'desc',
  })
  const updateReport = useUpdateReport()
  const { saveNewReport } = useSaveNewReport()
  const { editReport } = useEditReport()
  const { deleteReport } = useDeleteReport()

  const [columnsSidebarShown, setColumnsSidebarShown] = useState(false)

  const { csvDownloadURL } = useCsvExport({
    fileName: savedReport?.name || t('features.reporting.untitledReport'),
    columnIds,
    filterParams,
    sorted,
    reportType: type,
    groupBy,
  })

  // Reset page back to page 1 when filters change
  useEffect(() => setPageNumber(1), [filter])

  // When columns change:
  //  - Remove filters that no longer have associated columns
  //  - Change sort column if the current sort column is no longer selected
  useEffect(() => {
    const newFilters = { ...filter }

    // Find filter key for each selected column
    const filterKeysForSelectedColumns: (keyof ReportTypeMap[RT]['filters'])[] =
      columnIds.map(
        (columnId) =>
          infoByColumnId({ reportType: type, id: columnId }).filterKey,
      )

    // Remove filters that no longer have associated columns
    for (const key of Object.keys(
      newFilters,
    ) as (keyof ReportTypeMap[RT]['filters'])[]) {
      if (!filterKeysForSelectedColumns.includes(key)) {
        delete newFilters[key]
      }
    }

    setFilter(newFilters)

    // If sort column is no longer selected,
    // sort by the first selected column, unless no columns are selected
    if (!columnIds.includes(sorted.column) && columnIds.length > 0) {
      setSorted((sorted) => ({ ...sorted, column: columnIds[0] }))
    }

    // We only want to fire this when the columns change,
    // it's not necessary to fire it when the filter changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnIds])

  const { data: page, isLoading } = useReportPage(type, {
    filters: filterParams,
    page: pageNumber,
    pageSize,
    sortColumn: sorted && convertToSortApiColumn(type, sorted.column),
    sortDirection: sorted?.direction,
    ...toGroupByParams(groupBy),
  })

  const saveNew = () => {
    saveNewReport({
      filters: filter,
      columns: columnIds,
      sortColumn: sorted.column,
      sortDirection: sorted.direction,
      reportType: type,
      ...toGroupByParams(groupBy),
    })
  }

  const onSave = async () => {
    if (savedReport === undefined) {
      saveNew()
    } else {
      try {
        await updateReport.mutateAsync({
          id: savedReport.id,
          name: savedReport.name,
          description: savedReport.description,
          isPublic: savedReport.isPublic,
          filters: filter,
          columns: columnIds,
          sortColumn: sorted.column,
          sortDirection: sorted.direction,
          ...toGroupByParams(groupBy),
        })

        toast({
          title: t('features.reporting.reportSaved'),
          variant: 'success',
          content: t('features.reporting.successfullySavedReport'),
        })
      } catch (error) {
        toast({
          title: t('features.reporting.unableToSaveReport'),
          variant: 'error',
          content: t('features.reporting.failedToSaveReport'),
        })
      }
    }
  }

  const onEdit = () => {
    // Nothing to do if the report isn't saved
    if (savedReport === undefined) return

    editReport(savedReport)
  }

  const onDelete = () => {
    // Nothing to do if the report isn't saved
    if (savedReport === undefined) return

    deleteReport(savedReport)
  }

  const dirty = useMemo(() => {
    if (savedReport === undefined) {
      return true
    }

    if (savedReport.sortColumn !== sorted.column) return true
    if (savedReport.sortDirection !== sorted.direction) return true
    if (JSON.stringify(savedReport.filters) !== JSON.stringify(filter))
      return true

    if (
      savedReport.columns.length !== columnIds.length ||
      savedReport.columns.some((v, i) => columnIds[i] !== v)
    )
      return true

    const groupByParams = toGroupByParams(groupBy)
    if (
      savedReport.groupColumn !== groupByParams.groupColumn ||
      savedReport.groupDateInterval !== groupByParams.groupDateInterval
    )
      return true

    return false
  }, [savedReport, filter, sorted.column, sorted.direction, columnIds, groupBy])

  return (
    <div className="flex flex-grow">
      <div className="flex flex-col flex-grow">
        <Header
          title={
            savedReport
              ? savedReport.name
              : t('features.reporting.untitledReport')
          }
          italicizeTitle={savedReport === undefined}
          onSave={() => void onSave()}
          onSaveAs={() => saveNew()}
          onEdit={() => void onEdit()}
          onDelete={() => onDelete()}
          isPublic={savedReport?.isPublic ?? false}
          dirty={dirty}
          canSave={
            (savedReport === undefined ||
              signedInUser?.id === savedReport.owner.id) &&
            columnIds.length > 0
          }
          canEdit={
            savedReport !== undefined &&
            signedInUser?.id === savedReport.owner.id
          }
        />
        <div className="px-8 pt-6 pb-4 flex flex-col flex-grow">
          <SubHeader
            csvDownloadURL={csvDownloadURL}
            dateFilter={dateFilterForReportType(type, filter)}
            filters={filter}
            filtersShown={filtersShown}
            groupBy={groupBy}
            onClearAllFiltersClick={clearFilter}
            onColumnsSidebarClick={() =>
              setColumnsSidebarShown((shown) => !shown)
            }
            onFilterShownClick={() => setFiltersShown((shown) => !shown)}
            onGroupByChange={setGroupBy}
            onTotalRowShownClick={() => setTotalRowShown((shown) => !shown)}
            reportType={type}
            subtitle={
              page ? subtitleForReportType({ type, meta: page.meta, t }) : ''
            }
            totalRowShown={totalRowShown}
            totalRowSupported={page?.meta?.columnTotals !== undefined}
            totalRows={page?.meta.total}
          />
          <Table
            columnIds={columnIds}
            filter={filter}
            onHeaderClick={toggleColumnSort}
            page={page}
            pending={isLoading}
            setFilter={setFilter}
            showFilters={filtersShown}
            sorted={sorted}
            reportType={type}
            showTotalRow={totalRowShown}
          />
          <Footer
            onPageNumberChange={setPageNumber}
            onPageSizeChange={(size) => {
              setPageSize(size)
              setPageNumber(1)
            }}
            pageNumber={pageNumber}
            pageSize={page?.meta.pageSize ?? pageSize}
            pending={isLoading}
            totalPages={page?.meta.totalPages}
            totalRows={page?.meta?.total}
          />
        </div>
      </div>
      <ColumnsSidebar
        visible={columnsSidebarShown}
        onClose={() => setColumnsSidebarShown(false)}
        selectedColumnIds={columnIds}
        onColumnRemoved={(column) =>
          setColumnIds((columnIds) => columnIds.filter((id) => id !== column))
        }
        onColumnAdded={(column) =>
          setColumnIds((columnIds) => [...columnIds, column])
        }
        onColumnOrderChange={setColumnIds}
        reportType={type}
      />
    </div>
  )
})
