import { FC, ReactNode } from 'react'

export const Section: FC<{ title: string; children: ReactNode }> = ({
  children,
  title,
}) => (
  <section className="flex flex-col px-4 py-2 border-b border-neutral-300 text-sm last-of-type:border-b-0">
    <h1 className="text-neutral-500 uppercase font-medium text-nowrap text-xs mb-1">
      {title}
    </h1>
    {children}
  </section>
)
