import { AuditLogTargetType } from 'types'

export const translationForAuditLogTargetType = (
  targetType: AuditLogTargetType,
) => {
  switch (targetType) {
    case AuditLogTargetType.ApprovalGroup:
      return 'common.approvalGroup'
    case AuditLogTargetType.Project:
      return 'common.project'
    case AuditLogTargetType.Employee:
      return 'common.employee'
    case AuditLogTargetType.TimeCard:
      return 'common.timeCardsTitlePluralized_one'
    case AuditLogTargetType.ApiClient:
      return 'common.apiClient'
    case AuditLogTargetType.ApiKey:
      return 'common.apiKey'
  }
}
