import { useMemo } from 'react'
import { toDotSnakeCase } from '@utils/toDotSnakeCase'
import { infoByColumnId } from '../lib/infoByColumnId'
import * as changeKeys from 'change-case/keys'
import { getColumnGroupParent } from '../utils/getColumnGroupParent'
import { labelKeyByColumnGroupId } from '../lib/labelKeyByColumnGroupId'
import { filterApiSerializer } from '../utils/filterApiSerializer'
import { useTranslation } from 'react-i18next'
import { ReportTypeMap } from '../types'
import { Sorted } from './useSort'
import { convertToSortApiColumn } from '../utils/convertToApiSortColumn'
import { ReportFilter } from '@lib/api'
import { toGroupByParams } from '../utils/toGroupByParams'
import { GroupByValue } from '../types/groupByTypes'

const apiBaseUrl = import.meta.env.VITE_API_BASE_URL

type Options<RT extends keyof ReportTypeMap> = {
  columnIds: ReportTypeMap[RT]['columnId'][]
  fileName: string
  filterParams: ReportFilter<RT>
  groupBy?: GroupByValue
  reportType: RT
  sorted: Sorted
}

export const useCsvExport = <RT extends keyof ReportTypeMap>({
  columnIds,
  fileName,
  filterParams,
  groupBy,
  reportType,
  sorted,
}: Options<RT>) => {
  const { t } = useTranslation()

  const csvDownloadURL = useMemo(() => {
    const columns = columnIds.reduce<{ accessor: string; label: string }[]>(
      (acc, col) => [
        ...acc,
        {
          accessor: toDotSnakeCase(
            infoByColumnId({ reportType, id: col }).accessorKey,
          ),
          label: `${t(
            labelKeyByColumnGroupId({
              reportType,
              groupId: getColumnGroupParent(col),
            }),
          )} ${t(infoByColumnId({ reportType, id: col }).labelKey) as string}`,
        },
      ],
      [],
    )

    const params = {
      filters: JSON.stringify(filterApiSerializer(filterParams)),
      sortColumn:
        sorted &&
        toDotSnakeCase(convertToSortApiColumn(reportType, sorted.column)),
      sortDirection: sorted?.direction,
      columns: JSON.stringify(columns),
      fileName,
      ...toGroupByParams(groupBy),
    }

    return `${apiBaseUrl}/reports/${reportType}s/csv?${new URLSearchParams(
      changeKeys.snakeCase(params) as Record<string, string>,
    ).toString()}`
  }, [filterParams, sorted, columnIds, fileName, t, reportType, groupBy])

  return { csvDownloadURL }
}
