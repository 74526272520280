import { useCallback } from 'react'
import { useModal } from '@hooks/useModal'
import { useTranslation } from 'react-i18next'
import { SavedReport } from '../types'
import { useUpdateReport } from './useSavedReports'
import { ReportForm } from '../components/ReportForm'
import { toast } from '@lib/toasts'

export const useEditReport = () => {
  const modal = useModal()
  const { t } = useTranslation()
  const updateReport = useUpdateReport()

  const editReport = useCallback(
    (savedReport: SavedReport) => {
      const save = async (
        reportName: string,
        reportDescription: string,
        isPublic: boolean,
        modalId: number,
      ) => {
        try {
          await updateReport.mutateAsync({
            id: savedReport.id,
            name: reportName,
            description: reportDescription,
            isPublic,
            filters: savedReport.filters,
            columns: savedReport.columns,
            sortColumn: savedReport.sortColumn,
            sortDirection: savedReport.sortDirection,
            groupColumn: savedReport.groupColumn,
            groupDateInterval: savedReport.groupDateInterval,
          })

          modal.close(modalId)

          toast({
            title: t('features.reporting.reportSaved'),
            variant: 'success',
            content: t('features.reporting.successfullyUpdatedReport', {
              name: reportName,
            }),
          })
        } catch (error) {
          toast({
            title: t('features.reporting.unableToSaveReport'),
            variant: 'error',
            content: t('features.reporting.failedToSaveReport'),
          })
        }
      }

      const id = modal.form({
        title: t('features.reporting.editReport'),
        content: (
          <ReportForm
            savedReport={savedReport}
            save={(reportName, reportDescription, isPublic) =>
              void save(reportName, reportDescription, isPublic, id)
            }
            isSaving={updateReport.isLoading}
            cancel={() => modal.close(id)}
          />
        ),
      })
    },
    [modal, t, updateReport],
  )

  return { editReport }
}
