import { SavedReport } from '../types'
import { GroupByValue } from '../types/groupByTypes'

const groupParamMap = {
  'facility.id': 'facility',
  'user.id': 'user',
  none: 'none',
} as const

export const fromGroupByParams = (
  params?: Pick<SavedReport, 'groupColumn' | 'groupDateInterval'>,
): GroupByValue => {
  return {
    group: groupParamMap[params?.groupColumn ?? 'none'],
    interval: params?.groupDateInterval ?? 'none',
  }
}
